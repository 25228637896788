import React, { useState, useEffect } from 'react';
import { Select, SelectProps } from '../components/Select';
import { HomeButton, HomeButtonProps } from './HomeButton/HomeButton';

export const ViewerControls: React.FC<SelectProps & HomeButtonProps> = ({
    titleHint,
    setTitle,
    setSelectSource,
    zoomOut,
    initValue
}) => {
    const [isInit, setIsInit] = useState(false);
    const [didMouseIn, setDidMouseIn] = useState(false); // Keep track if mouse has been activated during init.
    const [isActive, setIsActive] = useState(true);

    // Hook to change active status on selection change.
    useEffect(() => {
        if (isInit && !didMouseIn) {
            setIsActive(false);
        }
    }, [didMouseIn, isInit, setSelectSource, setIsActive]);

    // uE hook to show controls for first five seconds.
    useEffect(() => {
        const isActiveTimeout = setTimeout(() => {
            setIsInit(true);
        }, 5000);
        
        return () => clearTimeout(isActiveTimeout);
    }, [setIsInit]);

    let viewerControlsClassNames = 'viewer-controls';
    if (isActive) viewerControlsClassNames = `${viewerControlsClassNames} viewer-controls--active`;

    return (
        <div
            onMouseEnter={() => {
                setIsActive(true);
                setDidMouseIn(true);
            }}
            onMouseLeave={() => {
                setIsActive(false);
                setDidMouseIn(false);
            }}
            className={`app__viewer-controls ${viewerControlsClassNames}`}
        >
            <Select
                titleHint={titleHint}
                setTitle={setTitle}
                setSelectSource={setSelectSource}
                initValue={initValue}
            />
            <HomeButton zoomOut={zoomOut}/>
        </div>
    )    
};
