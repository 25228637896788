import React, { useEffect, useState, useMemo } from 'react';
import DropdownSelect  from 'react-dropdown-select';

export enum SelectTypes {
    Artworks = 'artworks',
    Ensembles = 'ensembles',
};

/**
 * Convert index into string array for OSD tile sources.
 * @param {string} index numerical index for accessing image from S3.
 * @returns {string[]} tile source array to be passed to OSD.
 */
export const getTileSourceURL: ({ index: string, type: SelectTypes }) => string[] = ({ index, type }) => {
    if (type === SelectTypes.Artworks) return [`https://collection-tif-tiler.s3.amazonaws.com/tiles/${index}/info.json`];
    if (type === SelectTypes.Ensembles) return [`https://collection-tif-tiler.s3.amazonaws.com/ensembleTiles/${index}/info.json`];
};

export type SelectOption = { invno: string, title: string };

export type SelectProps = {
    setSelectSource: ({ index: string, type: SelectTypes }) => void;
    setTitle: (title: string) => void;
    initValue: { artworks: SelectOption, ensembles: SelectOption };
    titleHint: TitleHint
};

export type TitleHint = {
    invno?: string;
    ensembleIndex: string;
    type: SelectTypes
};

type TileResponse = {
    artworks: ArtworkTileSource[];
    ensembles: EnsembleTileSource[];
};

type ArtworkTileSource = {
    invno: string;
    ensembleIndex: number;
    title: string;
    id: number;
};

type EnsembleTileSource = {
    ensembleIndex: string;
    title: string;
};

/**
 * Select tileSource to be shown in IIIFViewer.
 */
export const Select: React.FC<SelectProps> = ({ titleHint, setSelectSource, initValue, setTitle }) => {
    // Memoized call to server, we want this to be as expedient as possible so store in sessionStorage.
    const memoizedSearch: Promise<TileResponse> = useMemo(async () => {
        
        // Check session storage.
        const sessionTiles = sessionStorage.getItem('tiles');

        try {
            // If there is tiles in sessionStorage, just return those.
            if (sessionTiles) {
                return JSON.parse(sessionTiles);
            
            // Otherwise, call to server and then write to sessionStorage.
            } else {
                const res = await fetch('/tiles');
                const tiles: TileResponse = await res.json();
                sessionStorage.setItem('tiles', JSON.stringify(tiles));
                return tiles;
            }
        } catch (e) {
            console.log(e);

            // Clear session storage.
            sessionStorage.clear();
        }
    }, []);

    // State hook for setting dropdown data.
    const [tileResponse, setTileResponse] = useState({ artworks: [], ensembles: [] } as TileResponse);

    // uE hook to set memoized data.
    useEffect(() => {
        (async () => {
            setTileResponse(await memoizedSearch);
        })();
    }, [memoizedSearch]);

    useEffect(() => {
        if (tileResponse && tileResponse.artworks.length && tileResponse.ensembles.length) {
            if (titleHint.type === SelectTypes.Ensembles) {                
                const foundTile = tileResponse.ensembles.find(({ ensembleIndex }) => ensembleIndex === titleHint.ensembleIndex);
                setTitle(foundTile ? foundTile.title : '');
            }

            if (titleHint.type === SelectTypes.Artworks) {
                const foundTile = tileResponse.artworks.find(({ invno }) => invno === titleHint.invno);
                setTitle(foundTile ? foundTile.title : '');
            }
        }
    }, [titleHint, setTitle, tileResponse])
    
    return (
        <div>
            <Dropdown
                data={tileResponse.artworks}
                initValue={initValue.artworks}
                setSelectSource={setSelectSource}
                type={SelectTypes.Artworks}
            />
            <Dropdown
                data={tileResponse.ensembles}
                initValue={initValue.ensembles}
                setSelectSource={setSelectSource}
                type={SelectTypes.Ensembles}
            />
        </div>
    )
};

type DropdownProps = {
    data: Array<ArtworkTileSource | EnsembleTileSource>;
    initValue: SelectOption;
    setSelectSource: ({ index: string, type: SelectTypes }) => void;
    type: SelectTypes;
};

const Dropdown: React.FC<DropdownProps> = ({ data, initValue, setSelectSource, type }) => {
    const [isDropdownActive, setIsDropdownActive] = useState(false);

    let selectWrapperClassName = 'select-wrapper';
    if (isDropdownActive) {
        selectWrapperClassName = `${selectWrapperClassName} select-wrapper--active`;
    }

    let options;
    if (type === SelectTypes.Artworks) {
        const artworks: ArtworkTileSource[] = data as ArtworkTileSource[];
        options = artworks.map(({ invno, title }) => ({ value: JSON.stringify({ invno, title, type: SelectTypes.Artworks }), text: `${invno} — ${title}` }));
    }

    if (type === SelectTypes.Ensembles) {
        const ensembles: EnsembleTileSource[] = data as EnsembleTileSource[];
        options = ensembles.map(({ ensembleIndex, title }) => ({ value: JSON.stringify({ ensembleIndex, title, type: SelectTypes.Ensembles }), text: `${title}` }));
    }

    return (
        <div className='viewer-controls__select'>
            <div className={selectWrapperClassName}>
                <DropdownSelect
                    className="select"
                    values={[{ value: JSON.stringify(initValue), text: `${initValue.invno} — ${initValue.title}` }]}

                    // The value is stringified/parsed on passing to prevent key clashing, as the react-dropdown-select assigns key according to value.
                    options={options}
                    onChange={(values) => {
                        if (values && values.length) {
                            const { invno, ensembleIndex, type: valType } = JSON.parse(values[0].value);

                            if (type === valType) {
                            // Execute parent callback.
                                setSelectSource({ index: invno || ensembleIndex, type });
                            }
                        }
                    }}
                    multi={false}
                    searchable={true}
                    clearOnSelect={true}
                    labelField="text"
                    valueField="value"
                    onDropdownOpen={() => setIsDropdownActive(true)}
                    onDropdownClose={() => setIsDropdownActive(false)}
                    placeholder="Search"
                    dropdownHeight="310px"

                    // Uncomment for testing.
                    // keepOpen={true as undefined}
                />
            </div>
        </div>
    );
}