import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useLocation, useHistory } from 'react-router-dom';
import querystring, { ParsedQuery } from 'query-string';
import { IIIFViewer } from './components/IIIFViewer';
import { ViewerControls } from './components/ViewerControls';
import { getTileSourceURL, SelectOption, SelectTypes, TitleHint } from './components/Select';
import './scss/index.scss';

/**
 * Custom hook to put function in state.
 * @param {function} fn - function to be stored in state.
 */
const useFunctionAsState: (fn: (...args: any[]) => any) => [(...args: any[]) => any, any] = (fn) => {
  const [val, setVal] = useState(() => fn);

  const setFunc = (fn) => {
    setVal(() => fn);
  }

  return [val, setFunc];
}

const App: React.FC = () => {
  const history = useHistory(); // For updating URL.
  const { search } = useLocation();
  
  const { invno, ensembleIndex }: ParsedQuery<string> = querystring.parse(search);

  let query: { index: string, type: SelectTypes } = { index: '01.01.01', type: SelectTypes.Artworks };
  if (invno) query = { index: invno as string, type: SelectTypes.Artworks };
  if (ensembleIndex) query = { index: ensembleIndex as string, type: SelectTypes.Ensembles };

  const [selectedSource, setSelectedSource] = useState(query);
  const [selectedSourceURL, setSelectedSourceURL] = useState(getTileSourceURL(selectedSource));
  const [title, setTitle] = useState('');

  useEffect(() => {
    setSelectedSourceURL(getTileSourceURL(selectedSource));
  }, [selectedSource]);

  // Update URL, compare current query params to next query params.
  useEffect(() => {
    // TODO => Fix this.
    if (`?${querystring.stringify(selectedSource)}` !== search) {      
      const { index, type } = selectedSource;
      const queryParams = type === SelectTypes.Artworks
        ? { invno: index }
        : { ensembleIndex: index };

      history.push({ search: querystring.stringify({ ...queryParams }) });
    }
  }, [search, selectedSource, history]);

  // Update title by setting state hook in select component.
  useEffect(() => {
    if (title) {
      document.title = `Collection — ${title}`;
    }
  }, [title])

  const [zoomOut, setZoomOut] = useFunctionAsState(null);

  const { type } = query;
  
  return (
    <div className="app">
      <ViewerControls
        titleHint={{
          invno: invno as string,
          ensembleIndex: ensembleIndex as string,
          type,
        } as TitleHint}
        setTitle={title => setTitle(title)}
        setSelectSource={source => setSelectedSource(source)}
        zoomOut={zoomOut}
        initValue={
          {
            artworks: Boolean(invno && title && type === SelectTypes.Artworks)
              ? { invno, title } as SelectOption
              : { invno: '01.01.01', title: 'Hinge' } as SelectOption,

            ensembles: Boolean(ensembleIndex && title && type === SelectTypes.Ensembles)
              ? { invno: ensembleIndex, title } as SelectOption
              : { invno: '1', title: 'Main Room, North Wall' } as SelectOption
          }
        }
      />
      <IIIFViewer
        tileSources={selectedSourceURL}
        setZoomOut={zoomFunc => setZoomOut(zoomFunc)}
      />
    </div>
  );
}

/** Wrap app in router so useLocation hook is available. */
const RouterWrapper = () => (
  <Router>
    <App />
  </Router>
)

export default RouterWrapper;
