import React, { useEffect, useRef } from 'react';
import OpenSeadragon from 'openseadragon';

type IIIFViewerProps = {
  tileSources: string[];
  setZoomOut: React.Dispatch<React.SetStateAction<void>>;
};

/**
 * Setup for IIIFViewer.
 * This will use a ref to drop out of react.
 */
export const IIIFViewer: React.FC<IIIFViewerProps> = ({ tileSources, setZoomOut }: IIIFViewerProps) => {
    const ref: React.Ref<HTMLDivElement> = useRef(null);

    // Pass ref to useEffect, canvas for IIIF viewer will be dropped underneath the ref.
    useEffect(() => {
      const osd = OpenSeadragon({
        element: ref.current,
        visibilityRatio: 1.0,
        constrainDuringPan: true,
        tileSources,
        navigatorBackground: '#1f1f1f',
        showNavigationControl: false,
        // maxZoomLevel: 10,
      });

      // Add event handler to resize and set home callback on images load.
      osd.addHandler('open', () => {
        const imageBounds = osd.world.getItemAt(0).getBounds();
        osd.viewport.fitBounds(imageBounds, true);

        setZoomOut(() => {
          osd.viewport.fitBounds(imageBounds);
        });
      }, []);

      return () => {
        osd.destroy();
      }
    }, [tileSources]);

    return (
        <div
          className="app__iiif-viewer iiif-viewer"
          ref={ref}
        >
        </div>
    );
};
