import React from 'react';
import { ReactComponent as HomeIcon } from './home.svg';

export type HomeButtonProps = {
    zoomOut: () => void;
}

export const HomeButton: React.FC<HomeButtonProps> = ({ zoomOut }) => (
    <button
        className="home-button"
        onClick={zoomOut}
    >
        <HomeIcon className="home-button__icon"/>
    </button>
);
